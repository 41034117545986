.section-library .Polaris-Header-Title__TitleWithMetadataWrapper {
  justify-content: space-between;
}

.section-filter-wrapper .Polaris-ButtonGroup {
  display: inline-flex;
}

.section-filter-wrapper .section-filter-price .section-filter-price-label {
  margin-right: 10px;
}